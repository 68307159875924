import { Injectable } from '@angular/core';
import * as moment from 'moment';

declare var $;

@Injectable()
export class Global {

    config;
    usuario;
    VERSION = 1.05;

    constructor() { }

    async get_config() {

        this.config = await this.readJson('config');

    }

    async apiCall(url, method, auth = false, data = null) {

        if (!this.config) this.config = await this.readJson('config');

        let headers = {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
        };

        if (auth) {

            let token = JSON.parse(localStorage.getItem('ERSM_TOKEN'));

            if (!token) return;

            let access_token = token.access_token;

            headers['Authorization'] = "Bearer " + access_token;

        }

        let settings = {
            url: this.config.api + url,
            method: method,
            headers: headers,
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                // alert("Error al comunicarse con API REST: " + url);
            }
        };

        if (data) {

            settings['data'] = JSON.stringify(data)

        }

        return await $.ajax(settings);

    }

    async readJson(JSON) {

        let headers = {
            "Content-Type": "application/json"
        };

        let settings = {
            cache: false,
            url: "assets/json/" + JSON + ".json",
            method: 'GET',
            headers: headers
        };

        return await $.ajax(settings);

    }

    async checkVersion() {

        let apiVersion = await this.apiCall('version?app=DUPLICADOPOLIZAS', 'GET', false);

        if (apiVersion != this.VERSION) location.reload();

    }

}