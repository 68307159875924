import { Component, OnInit } from '@angular/core';
import { Global } from '../global';

declare var $;

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.scss']
})
export class BuscadorComponent implements OnInit {

  cargando = false;
  poliza;
  solicitar = false;
  paso = 1;
  showCaptcha;
  errorShowcaptcha;
  formulario;
  showAlertError;
  duplicados = [];
  cias = [
    {
      cia: 'ALLIANZ',
    },{
      cia: 'AXA',
      options:[
        {
          description: 'Póliza para cliente',
          value: '31'
        },
        {
          description: 'Póliza para ERSM',
          value: '35'
        }, {
          description: 'Resumen de la póliza',
          value: '12'
        }, {
          description: 'Carta verde',
          value: '22'
        }
      ]
    },{
      cia: 'CASER',
    },{
      cia: 'MAPFRE',
    },{
      cia: 'PELAYO',
      options:[
        {
          description: 'Carta de garantías',
          value: '1'
        },{
          description: 'Condiciones particulares',
          value: '2'
        }, {
          description: 'Condiciones particulares con logo',
          value: 'B'
        }, {
          description: 'Carta de garantías y Condiciones particulares',
          value: '3'
        }, {
          description: 'Recibo del Alta',
          value: '4'
        }, {
          description: 'Recibo del Alta con logo',
          value: 'D'
        }, {
          description: 'Certificado siniestralidad',
          value: '5'
        }, {
          description: 'Certificado siniestralidad con logo',
          value: 'E'
        }
      ]
    },{
      cia: 'REALE',
    }
  ]

  constructor(public global: Global) { }

  ngOnInit() {
    this.iniForm();
    this.paso = 1;
  }

  iniForm() {
    this.formulario = {
      poliza: {
        value: '',
        error: null,
      },
      nif: {
        value: '',
        error: null,
      }, cia: {
        value: '',
        error: null,
      },option: {
        value: '',
        error: null,
      }
    }
    if (sessionStorage.getItem('token-duplicado-polizas')) {
      this.showCaptcha = false;
    } else {
      this.showCaptcha = true;
    }
    this.errorShowcaptcha = '';
    this.showAlertError = null;
  }

  iniTable() {
    setTimeout(() => {
      $('#duplicadosTable').DataTable({
        lengthMenu: [150],
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
        },
      });
    }, 100);
  }

  resolved(captchaResponse: string) {
    sessionStorage.setItem('token-duplicado-polizas', captchaResponse);
    this.errorShowcaptcha = null;
  }

  solicitarDuplicado() {
    this.iniForm();
    this.paso = 1;
    this.poliza = null;
    this.solicitar = true;
  }

  async paso2() {

    var r = true;

    if (!sessionStorage.getItem("token-duplicado-polizas")) {
      this.errorShowcaptcha = "Completa el campo";
      r = false;
    }

    if (this.formulario.poliza.value == '' || this.formulario.poliza.value == null) {
      this.formulario.poliza.error = 'Por favor, indique el número de la póliza que desea buscar';
      r = false;
    }
    if (this.formulario.nif.value == '' || this.formulario.nif.value == null) {
      this.formulario.nif.error = 'Por favor, indique el NIF/CIF asociado a la póliza';
      r = false;
    }
    if (this.formulario.cia.value == '' || this.formulario.cia.value == null) {
      this.formulario.cia.error = 'Por favor, indique la compañía aseguradora';
      r = false;
    }

    if (!r) {
      return;
    }
    await this.buscarPoliza();
    this.duplicados = [];
    $('#duplicadosTable').DataTable().destroy();
    await this.iniTable();
    if(!this.poliza) return;
    this.paso = 2;
  }

  async buscarPoliza() {

    this.showAlertError = null;

    if (this.formulario.poliza.value == '80221431') {
      this.poliza = {
        cia: "AXA",
        cli_apellido1: "GONZALEZ",
        cli_apellido2: 'HUERTA',
        cli_entidad: "2",
        nif: "40992179S",
        cli_nombre: 'SILVIA',
        pol_id: "10711353",
        poliza: "80221431",
      }
    } else {
      this.cargando = true;
      let result = await this.global.apiCall('duplicadoPoliza/getPoliza', 'POST', false, { numPoliza: this.formulario.poliza.value, nif: this.formulario.nif.value, cia: this.formulario.cia.value });
      this.cargando = false;
      if (!result.status) {
        this.showAlertError = result.message;
        this.poliza = null;
        return;
      }
      this.poliza = result.data;
      if(this.poliza.Ramo == 'AUTO') this.poliza.Ramo="30"
      if(this.poliza.Ramo == 'HOGAR') this.poliza.Ramo="95"
      if(this.poliza.Ramo == 'NEGOCIOS') this.poliza.Ramo="96"
      if(this.poliza.Ramo == 'COMUNIDADES') this.poliza.Ramo="98"
      if(this.poliza.Ramo == 'PYME') this.poliza.Ramo="92"
      if(this.poliza.Ramo == 'ACCIDENTES') this.poliza.Ramo="21"
    }
  }

  cancelar() {
    this.solicitar = false;
  }

  seletMore(){
    if(this.formulario.cia.value == '' || this.formulario.cia.value == null) return false;
    let cia = this.cias.find(c => c.cia == this.formulario.cia.value);
    if(!cia.options){
      return false;
    }
    return true;
  }

  optionsOfCia(){
    if(this.formulario.cia.value == '' || this.formulario.cia.value == null) return false;
    let cia = this.cias.find(c => c.cia == this.formulario.cia.value);
    if(!cia.options){
      return false;
    }
    return cia.options;
  }

  async descargarPoliza() {

    this.duplicados = [];
    
    if(this.optionsOfCia() && this.formulario.option.value == '' || this.formulario.option.value == null){
      this.formulario.option.error = 'Por favor, indique el tipo de documento';
      return;
    }

    this.cargando = true;
    let result = await this.global.apiCall('duplicadoPoliza/get_duplicado_poliza', 'POST', false, { c: this.formulario.cia.value, p: this.formulario.poliza.value, ramo: this.poliza.Ramo, tipoDocumento: this.formulario.option.value });
    this.cargando = false;
    if (result.status == false) {
      if(result.error.descripcion){
        alert(result.error.Descripcion);
      } else {
        alert(result.error);
      }
      return;
    }

    console.log(result)

    for (var i = 0; i < result.data.length; i++) {
      this.duplicados.push({
        "name": "Duplicado de la póliza " + this.poliza.Poliza + "-" + (i + 1),
        "url": result.data[i]
      });
    }

    console.log(this.duplicados)

    $('#duplicadosTable').DataTable().destroy();
    this.iniTable();
  }

}

